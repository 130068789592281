import React from "react";
import './App.css'
import "bootstrap/dist/css/bootstrap.min.css"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import DigitalEvaluvation from "./Components/DigitalEvaluvation";
import VirtualExperts from "./Components/VirtualExperts";
import CustomerStory from "./Components/CustomerStory";
import MainPage from "./Components/MainPage";
import BlendL from "./Components/BlendL";
import ETools from "./Components/ETools"


function App() {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />}>
            <Route path="/" element={<Home />} />
            <Route path="/BlendL" element={<BlendL />} />
            <Route path="/DigitalEvaluvation" element={<DigitalEvaluvation />} />
            <Route path="/VirtualExperts" element={<VirtualExperts />} />
            <Route path="/ETools" element={<ETools />} />
            <Route path="/CustomerStory" element={<CustomerStory />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
