import React from 'react';
import bgZero from '../Assets/bg-0.png';
import HomeImg from "../Assets/Home.png"
import BlendL from "../Assets/Blendl.png"
import Digital from "../Assets/Digital.png"
import Virtual from "../Assets/Virtual.png"
import ETools from "../Assets/Etool.png"
import Custome from "../Assets/Custome.png"
import { Fade } from 'react-reveal';
import { FaAngleDoubleRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigation = useNavigate();
    return (
        <div className='W-100'>
            <div className='Desktop'>
                <div className='bg_sandal d-flex align-items-center justify-content-evenly pt-4 px-5'>
                    <img src={bgZero} style={{ width: "100%" }} alt='bgzero' />
                </div>
            </div>
            <div className='Mobile'>
                <div className='W-100 d-flex flex-column'>
                    <div className="w-100">
                        <img src={HomeImg} alt="HomeImg" className="w-100" />
                    </div>
                    <div className="w-100">
                        <Fade right>
                            <img src={BlendL} alt="BlendL" className="w-100" />
                        </Fade>
                    </div>
                    <div style={{ backgroundColor: "#00001C" }} className="w-100 clr_white fs-4 font_family fw-normal know_more"
                        onClick={() => navigation('/')}>
                        <Fade right cascade collapse>
                            <div className='d-flex align-items-center gap-2 justify-content-center'>
                            <span>Know More</span>
                        <FaAngleDoubleRight />
                            </div>
                        </Fade>
                    </div>
                    <div className="w-100">
                        <Fade left>
                            <img src={Digital} alt="Digital" className="w-100" />
                        </Fade>
                    </div>
                    <div style={{ backgroundColor: "#DCBE89" }} className="w-100 clr_blue fs-4 d-flex align-items-center gap-2 justify-content-center font_family know_more"
                        onClick={() => navigation('/')}>
                        <Fade left cascade collapse>
                            <div className='d-flex align-items-center gap-2 justify-content-center'>
                            <span>Know More</span>
                        <FaAngleDoubleRight />
                            </div>
                        </Fade>
                    </div>
                    <div className="w-100">
                        <Fade right><img src={Virtual} alt="Virtual" className="w-100" /></Fade>
                    </div>
                    <div style={{ backgroundColor: "#00001C" }} className="w-100 clr_white fs-4 d-flex align-items-center gap-2 justify-content-center font_family know_more"
                        onClick={() => navigation('/')}>
                        <Fade right cascade collapse>
                            <div className='d-flex align-items-center gap-2 justify-content-center'>
                            <span>Know More</span>
                        <FaAngleDoubleRight />
                            </div>
                        </Fade>
                    </div>
                    <div className="w-100">
                        <Fade left>
                        <img src={ETools} alt="ETools" className="w-100" />
                        </Fade>
                    </div>
                    <div style={{ backgroundColor: "#DCBE89" }} className="w-100 clr_blue fs-4 d-flex align-items-center gap-2 justify-content-center font_family know_more"
                        onClick={() => navigation('/')}>
                        <Fade left cascade collapse>
                            <div className='d-flex align-items-center gap-2 justify-content-center'>
                            <span>Know More</span>
                        <FaAngleDoubleRight />
                            </div>
                        </Fade>
                    </div>
                    <div className="w-100 bg_blue p-1">
                        <Fade right>
                            <img src={Custome} alt="CaseStudy" className="w-100" />
                        </Fade>
                    </div>
                    <div style={{ backgroundColor: "#00001C" }} className="w-100 clr_white fs-4 d-flex align-items-center gap-2 justify-content-center font_family know_more"
                        onClick={() => navigation('/')}>
                        <Fade right cascade collapse>
                            <div className='d-flex align-items-center gap-2 justify-content-center'>
                            <span>Know More</span>
                        <FaAngleDoubleRight />
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home