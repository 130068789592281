import React from 'react'
import { Fade } from 'react-reveal'
import bgOne from '../Assets/bg-1.png'
import bgTwo from '../Assets/bg-2.png'
import bgThree from '../Assets/bg-3.png'
import Blendl from "../Assets/Blendl.png"
import BlendL_One from "../Assets/BlendL-1.png"
import BlendL_Two from "../Assets/BlendL-2.png"

const BlendL = () => {
  const handleButtonClick = () => {
    //URL replace location
  window.location.href = 'https://blendl.tepros.in/';
};
  return (
    <div>
      <div className='Desktop'>
      <div className='d-flex flex-column justify-content-center align-items-center'>
                 <div className='bg_sandal w-100 p-5 position-relative'>
                 <Fade left>
                   <img className='w-100' src={bgOne} alt='bgone' />
                   <div className='demo_btn' onClick={() => handleButtonClick()}>Demo</div>
                 </Fade>
                 </div>
                 <div className='bg_blue  w-100 p-5'>
                 <Fade right>
                   <img className='w-100' src={bgTwo} alt='bgtwo' />
                 </Fade>
                 </div>
            
                 <div className='bg_sandal w-100 p-5'>
                 <Fade left>
                   <img className='w-100' src={bgThree} alt='bgthreen' />
                 </Fade>
                 </div>
               </div>
      </div>
      <div className='Mobile'>
      <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                 <div className="w-100 position-relative">
                  <Fade left>
                   <img className="w-100" src={Blendl} alt="BlendL" />
                   <div className='demo_btn' onClick={() => handleButtonClick()}>Demo</div>
                   </Fade>
                 </div>
                 <div className="hr_line"></div>
                 <div className="w-100 bg_blue py-3">
                 <Fade right>
                   <img className="w-100" src={BlendL_One} alt="BlendL" />
                   </Fade>
                 </div>
                 <div className="hr_line"></div>
                 <div className="w-100 bg_sandal py-3">
                 <Fade left>
                   <img className="w-100" src={BlendL_Two} alt="BlendL" />
                   </Fade>
                 </div>
               </div>
      </div>
    </div>
  )
}

export default BlendL