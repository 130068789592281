import { CModal, CModalBody } from '@coreui/react-pro'
import React, { useState } from 'react'
import { Fade, Flip } from 'react-reveal'
import CaseStudy from '../Assets/CaseStudy.png'
import VIT_Logo from '../Assets/VIT-logo.png'
import NMIMS_Logo from '../Assets/NMIMS-Logo.png'
import Model_One from '../PDF/ModelOne.pdf'
import Model_Two from '../PDF/ModelTwo.pdf'
import Model_Three from '../PDF/ModelThree.pdf'
import Custome from "../Assets/Custome.png"
import { IoMdClose } from "react-icons/io";

const CustomerStory = () => {


  const [visible, setVisible] = useState(false)
  const [mobileVisible, setMobileVisible] = useState(false)
  const [modal, setModal] = useState(0)

  const modalOneOpen = () => {
    setVisible(true)
    setModal(1)
  }

  const modalTwoOpen = () => {
    setVisible(true)
    setModal(2)
  }
  const modalThreeOpen = () => {
    setVisible(true)
    setModal(3)
  }

  const modelClose = () => {
    setVisible(false)
    setModal(0)
  }

  const mobileModalOneOpen = () => {
    setMobileVisible(true)
    setModal(1)
  }

  const mobileModalTwoOpen = () => {
    setMobileVisible(true)
    setModal(2)
  }
  const mobileModalThreeOpen = () => {
    setMobileVisible(true)
    setModal(3)
  }

  const mobileModelClose = () => {
    setMobileVisible(false)
    setModal(0)
  }

  return (
    <div>
      <div className='Desktop'>
        <div className="pb-5 bg_sandal">
          <div className='d-flex flex-column justify-content-center align-items-center bg_sandal py-5'>
            <div className=' d-flex align-items-center justify-content-between col-12 p-5'>
              <div className='col-4 p-3'>
                <Fade left>
                  <img className='w-100' src={CaseStudy} alt='CaseStudy' />
                </Fade>
              </div>
              <Flip right cascade collap>
                <div className='col-7 d-flex align-items-center justify-content-evenly gap-3'>
                  <div className='w-25 bg_blue p-3 cursur_poiter shadow' onClick={() => modalOneOpen()}>
                    <div className='w-100 bg_sandal p-1'>
                      <div className='w-100 bg_blue p-3 d-flex flex-column align-items-center gap-2'>
                        <img style={{ width: "100%" }} src={VIT_Logo} alt='VIT_LOGO' />
                        <div className='Logo_TXT'>VIT-CHENNAI</div>
                      </div>
                    </div>
                  </div>
                  <div className='w-25 bg_blue p-3 cursur_poiter shadow' onClick={() => modalTwoOpen()}>
                    <div className='w-100 bg_sandal p-1'>
                      <div className='w-100 bg_blue p-3 d-flex flex-column align-items-center gap-2'>
                        <img className='w-100' src={NMIMS_Logo} alt='NMIMS_LOGO' />
                        <div className='Logo_TXT'>NMIMS</div>
                      </div>
                    </div>
                  </div>
                  <div className='w-25 bg_blue p-3 cursur_poiter shadow' onClick={() => modalThreeOpen()}>
                    <div className='w-100 bg_sandal p-1'>
                      <div className='w-100 bg_blue p-3 d-flex flex-column align-items-center gap-2'>
                        <img className='w-100' src={NMIMS_Logo} alt='NMIMS_LOGO' />
                        <div className='Logo_TXT'>NMIMS</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Flip>
            </div>

            <CModal alignment="center" visible={visible} onClose={() => modelClose()} aria-labelledby="VerticallyCenteredExample" size="xl">
              <CModalBody>
                <embed
                  src={modal === 1 ? Model_One : modal === 2 ? Model_Two : modal === 3 ? Model_Three : ""}
                  type="application/pdf"
                  className="Model w-100" />
                <IoMdClose className="close_Icon cursur_poiter" onClick={() => modelClose()} />
              </CModalBody>
            </CModal>
          </div>
        </div>
      </div>
      <div className='Mobile'>
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <div className="w-100 bg_blue p-1">
            <Fade left>
            <img src={Custome} alt="CaseStudy" className="w-100" />
            </Fade>
          </div>
          <div className="hr_line"></div>
          <div className="w-100 bg_blue py-3">
              <Flip right cascade collap>
            <div className="bg_blue customer_story_container d-flex justify-content-center align-items-center gap-3 p-5">
              <div className='customer_story_box bg_blue p-3 cursur_poiter shadow' onClick={() => mobileModalOneOpen()}>
                <div className='w-100 bg_sandal p-1'>
                  <div className='w-100 bg_blue p-3 d-flex flex-column align-items-center gap-2'>
                    <img style={{ width: "92%" }} src={VIT_Logo} alt='VIT_LOGO' />
                    <div className='Logo_TXT'>VIT-CHENNAI</div>
                  </div>
                </div>
              </div>
              <div className='customer_story_box bg_blue p-3 cursur_poiter shadow' onClick={() => mobileModalTwoOpen()}>
                <div className='w-100 bg_sandal p-1'>
                  <div className='w-100 bg_blue p-3 d-flex flex-column align-items-center gap-2'>
                    <img className='w-100' src={NMIMS_Logo} alt='NMIMS_LOGO' />
                    <div className='Logo_TXT'>NMIMS</div>
                  </div>
                </div>
              </div>
              <div className='customer_story_box bg_blue p-3 cursur_poiter shadow' onClick={() => mobileModalThreeOpen()}>
                <div className='w-100 bg_sandal p-1'>
                  <div className='w-100 bg_blue p-3 d-flex flex-column align-items-center gap-2'>
                    <img className='w-100' src={NMIMS_Logo} alt='NMIMS_LOGO' />
                    <div className='Logo_TXT'>NMIMS</div>
                  </div>
                </div>
              </div>
            </div>
            </Flip>
              <CModal alignment="center" visible={mobileVisible} onClose={() => mobileModelClose()} aria-labelledby="VerticallyCenteredExample">
                <CModalBody>
                  <embed
                    src={modal === 1 ? Model_One : modal === 2 ? Model_Two : modal === 3 ? Model_Three : ""}
                    type="application/pdf"
                    className="Model w-100" />
                  <IoMdClose className="close_Icon cursur_poiter" onClick={() => mobileModelClose()} />
                </CModalBody>
              </CModal>
          </div>
          <div className="hr_line"></div>
        </div>
      </div>
    </div>
  )
}

export default CustomerStory