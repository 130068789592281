import React from 'react'
import { Fade, Flip } from 'react-reveal'
import bgTen from '../Assets/bg-10.png'
import bgEleven from '../Assets/bg-11.png'
import Card7 from "../Assets/Group 63.png"
import Card8 from "../Assets/Group 64.png"
import Card9 from "../Assets/Group 65.png"
import ETool from "../Assets/Etool.png"
import ETools_Two from "../Assets/Etool-2.png"

const ETools = () => {
  return (
    <div>
      <div className='Desktop'>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <div className='bg_sandal w-100 p-5'>
            <Fade left>
              <img className='w-100' src={bgTen} alt='bgten' />
            </Fade>
          </div>
          <div className='bg_blue  w-100 p-5 d-flex flex-column gap-3 align-items-center justify-content-center'>
            <div className="Content_Head">E-Tools</div>
            <Flip left cascade collap>
              <div className='bg_blue  w-100 p-5 d-flex gap-5 align-items-center justify-content-center'>
                {/* <img className='w-100' src={bgFive} alt='bgfive' /> */}
                <div className="w-25">
                  <img src={Card7} alt="Card" className="w-100" />
                </div>
                <div className="w-25">
                  <img src={Card8} alt="Card" className="w-100" />
                </div>
                <div className="w-25">
                  <img src={Card9} alt="Card" className="w-100" />
                </div>
              </div>
            </Flip>
          </div>
          <div className='bg_sandal w-100 p-5'>
            <Fade right>
              <img className='w-100' src={bgEleven} alt='bgeleven' />
            </Fade>
          </div>
        </div>
      </div>
      <div className='Mobile'>
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <div className="w-100">
            <img className="w-100" src={ETool} alt="BlendL" />
          </div>
          <div className="hr_line"></div>
          <div className='bg_blue  w-100 py-5 px-2 d-flex flex-column gap-2 align-items-center justify-content-center'>
            <div className="Content_Head_Mobile">E-Tools</div>
            <Flip left cascade collap>
              <div className='bg_blue  w-100 d-flex gap-3 align-items-center justify-content-center'>
                {/* <img className='w-100' src={bgFive} alt='bgfive' /> */}
                <div style={{ width: "30%" }}>
                  <img src={Card7} alt="Card" className="w-100" />
                </div>
                <div style={{ width: "30%" }}>
                  <img src={Card8} alt="Card" className="w-100" />
                </div>
                <div style={{ width: "30%" }}>
                  <img src={Card9} alt="Card" className="w-100" />
                </div>
              </div>
            </Flip>
          </div>
          <div className="hr_line"></div>
          <div className="w-100 bg_sandal py-3">
            <img className="w-100" src={ETools_Two} alt="BlendL_Two" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ETools