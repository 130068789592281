import React from 'react'
import { Fade, Flip } from 'react-reveal'
import bgSeven from '../Assets/bg-7.png'
import bgNine from '../Assets/bg-9.png'
import Card4 from "../Assets/Group 60.png"
import Card5 from "../Assets/Group 61.png"
import Card6 from "../Assets/Group 62.png"
import Virtual from "../Assets/Virtual.png"
import Virtual_Two from "../Assets/Virtual-2.png"

const VirtualExperts = () => {
  return (
    <div>
      <div className='Desktop'>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <div className='bg_sandal w-100 p-5'>
            <Fade left>
              <img className='w-100' src={bgSeven} alt='bgseven' />
            </Fade>
          </div>
          <div className='bg_blue  w-100 p-5 d-flex flex-column gap-5 align-items-center justify-content-center'>
            <div className="Content_Head">Virtual Experts</div>
            <Flip right cascade collap>
              <div className='bg_blue  w-100 d-flex gap-5 align-items-center justify-content-center'>
                {/* <img className='w-100' src={bgFive} alt='bgfive' /> */}
                <div className="w-25">
                  <img src={Card4} alt="Card" className="w-100" />
                </div>
                <div className="w-25">
                  <img src={Card5} alt="Card" className="w-100" />
                </div>
                <div className="w-25">
                  <img src={Card6} alt="Card" className="w-100" />
                </div>
              </div>
            </Flip>
          </div>
          <div className='bg_sandal w-100 p-5'>
            <Fade right>
              <img className='w-100' src={bgNine} alt='bgnine' />
            </Fade>
          </div>
        </div>
      </div>
      <div className='Mobile'>
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <div className="w-100">
            <Fade left>
            <img className="w-100" src={Virtual} alt="BlendL" />
            </Fade>
          </div>
          <div className="hr_line"></div>
          <div className='bg_blue w-100 py-5 px-2 d-flex flex-column gap-2 align-items-center justify-content-center'>
            <div className="Content_Head_Mobile">Virtual Experts</div>
            <Flip right cascade collap>
              <div className='bg_blue  w-100 d-flex gap-3 align-items-center justify-content-center'>
                {/* <img className='w-100' src={bgFive} alt='bgfive' /> */}
                <div style={{ width: "30%" }}>
                  <img src={Card4} alt="Card" className="w-100" />
                </div>
                <div style={{ width: "30%" }}>
                  <img src={Card5} alt="Card" className="w-100" />
                </div>
                <div style={{ width: "30%" }}>
                  <img src={Card6} alt="Card" className="w-100" />
                </div>
              </div>
            </Flip>
          </div>
          <div className="hr_line"></div>
          <div className="w-100 bg_sandal py-3">
            <Flip right>
            <img className="w-100" src={Virtual_Two} alt="BlendL_Two" />
            </Flip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VirtualExperts