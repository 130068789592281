/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import Tepros from '../Assets/Tepros.png';
import Copy_Right from '../Assets/Copy right.png'
import Copy_Right_Mobile from '../Assets/CopyRightMobile.png'
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { IoReorderThree } from "react-icons/io5";

const MainPage = () => {

    const navigation = useNavigate()
    const page = window.location.pathname

  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className='App'>
        <div className='Desktop'>
        <div className='Header'>
          <div className='bg_blue py-2'>
            <div className='Header_component d-flex align-items-end justify-content-between px-5 pt-3'>
              <NavLink to='/' style={{ width: "140px", height: "140px" }} >
                <img src={Tepros} alt='Tepose' style={{ width: "100%" }} />
              </NavLink>
              <div className='d-flex align-items-center btn_container'>
                <div className={`${page === '/BlendL' ? 'btn_Active' : 'btn_Deactive'}  btn_box btn_one cursur_poiter`} onClick={() => navigation('/BlendL')}>Blend - L</div>
                <div className={`${page === '/DigitalEvaluvation' ? 'btn_Active' : 'btn_Deactive'}  btn_box btn_two cursur_poiter`} onClick={() => navigation('/DigitalEvaluvation')}>Digital evaluvation</div>
                <div className={`${page === '/VirtualExperts' ? 'btn_Active' : 'btn_Deactive'}  btn_box btn_three cursur_poiter`} onClick={() => navigation('/VirtualExperts')}>Virtual Experts</div>
                <div className={`${page === '/ETools' ? 'btn_Active' : 'btn_Deactive'}  btn_box btn_four cursur_poiter`} onClick={() => navigation('/ETools')}>E - Tools</div>
                <div className={`${page === '/CustomerStory' ? 'btn_Active' : 'btn_Deactive'}  btn_box btn_five cursur_poiter`} onClick={() => navigation('/CustomerStory')}>Customer story</div>
              </div>
            </div>
          </div>
          <div className='hr_line'></div>
        </div>
        </div>
        <div className='Mobile'>
        <div className='Header'>
          <div className='bg_blue py-2'>
            <div className='Header_component d-flex align-items-end justify-content-between px-3 py-3'>
              <NavLink style={{ width: "25%" }} to='/'>
                <img src={Tepros} alt='Tepose' style={{ width: "100%" }} />
              </NavLink>
              <div>
                <IoReorderThree className="Menu_Icon" onClick={() => setShowMenu(!showMenu)} />
                {showMenu &&
                  <div className='d-flex align-items-start flex-column Mobile_Menu_Container bg_blue' onClick={() => setShowMenu(false)}>
                    <div className={` ${page === '/BlendL' ? "bg_sandal clr_blue" : "bg_blue clr_orange"} Mobile_btn w-100 p-2 px-3`} onClick={() => navigation('/BlendL')}>Blend - L</div>
                    <div className={` ${page === '/DigitalEvaluvation' ? "bg_sandal clr_blue" : "bg_blue clr_orange"} Mobile_btn w-100 p-2 px-3`} onClick={() => navigation('/DigitalEvaluvation')}>Digital evaluvation</div>
                    <div className={` ${page === '/VirtualExperts' ? "bg_sandal clr_blue" : "bg_blue clr_orange"} Mobile_btn w-100 p-2 px-3`} onClick={() => navigation('/VirtualExperts')}>Virtual Experts</div>
                    <div className={` ${page === '/ETools' ? "bg_sandal clr_blue" : "bg_blue clr_orange"} Mobile_btn w-100 p-2 px-3`} onClick={() => navigation('/ETools')}>E - Tools</div>
                    <div className={` ${page === '/CustomerStory' ? "bg_sandal clr_blue" : "bg_blue clr_orange"} Mobile_btn w-100 p-2 px-3`} onClick={() => navigation('/CustomerStory')}>Customer story</div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className='hr_line'></div>
        </div>
        </div>
        <Outlet/>
        <div className='w-100'>
            <img src={Copy_Right} alt='CopyRights' className='w-100 Desktop'/>
            <img src={Copy_Right_Mobile} alt='CopyRights' className='w-100 Mobile'/>
        </div>
    </div>
  )
}

export default MainPage