import React from 'react'
import { Fade, Flip } from 'react-reveal'
import bgFour from '../Assets/bg-4.png'
import bgSix from '../Assets/bg-6.png'
import Card1 from "../Assets/Group 57.png"
import Card2 from "../Assets/Group 58.png"
import Card3 from "../Assets/Group 59.png"
import Digital from "../Assets/Digital.png"
import Digital_Two from "../Assets/Digital-2.png"

const DigitalEvaluvation = () => {
  return (
    <div>
      <div className='Desktop'>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <div className='bg_sandal w-100 p-5'>
            <Fade left>
              <img className='w-100 mb-5' src={bgFour} alt='bgfour' />
            </Fade>
          </div>
          <div className='bg_blue  w-100 p-5 d-flex flex-column gap-5 align-items-center justify-content-center'>
            <div className="Content_Head">Digital Evaluvation</div>
            <Flip left cascade collap>
              <div className='bg_blue  w-100 d-flex gap-5 align-items-center justify-content-center'>
                {/* <img className='w-100' src={bgFive} alt='bgfive' /> */}
                <div className="w-25">
                  <img src={Card1} alt="Card" className="w-100" />
                </div>
                <div className="w-25">
                  <img src={Card2} alt="Card" className="w-100" />
                </div>
                <div className="w-25">
                  <img src={Card3} alt="Card" className="w-100" />
                </div>
              </div>
            </Flip>
          </div>
          <div className='bg_sandal w-100 p-5'>
            <Fade right>
              <img className='w-100' src={bgSix} alt='bgsix' />
            </Fade>
          </div>
        </div>
      </div>
      <div className='Mobile'>
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <div className="w-100">
            <img className="w-100" src={Digital} alt="BlendL" />
          </div>
          <div className="hr_line"></div>
          <div className='bg_blue  w-100 py-5 px-2 d-flex flex-column gap-2 align-items-center justify-content-center'>
            <div className="Content_Head_Mobile">Digital Evaluvation</div>
            <Flip left cascade collap>
              <div className='bg_blue  w-100 d-flex gap-3 align-items-center justify-content-center'>
                {/* <img className='w-100' src={bgFive} alt='bgfive' /> */}
                <div style={{ width: "30%" }}>
                  <img src={Card1} alt="Card" className="w-100" />
                </div>
                <div style={{ width: "30%" }}>
                  <img src={Card2} alt="Card" className="w-100" />
                </div>
                <div style={{ width: "30%" }}>
                  <img src={Card3} alt="Card" className="w-100" />
                </div>
              </div>
            </Flip>
          </div>
          <div className="hr_line"></div>
          <div className="w-100 bg_sandal py-3">
            <img className="w-100" src={Digital_Two} alt="BlendL_Two" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DigitalEvaluvation